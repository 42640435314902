#container {
    padding-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

#hero {
    display: flex;
    justify-content: center;
    margin: 0 20px;
    background-color: hsla(55, 11%, 22%, .25);
    border: 1px solid hsl(50, 2%, 48%);
    border-radius: .15em;
}

p {
    color: #ffffff;
    text-align: justify;
    text-justify: inter-word;
    padding: 2vh 20px;
}

a {
    color: #a6e22e
}

.hello {
    font-family: 'Space Mono', monospace;
    color: #F92672;
    font-size: 1.2rem;
  }

  .lighter {
    color: #F92672;
    font-weight: 500;
  
  }
@media screen and (min-width: 1024px){
 #hero {
margin-top: 40px;
}
#container {
    padding-top: 0;
}
}