*,
*::after,
*::before {
  box-sizing: border-box;
}

body {
  background-color: #272822;
}

.App {
  text-align: center;
  justify-content: center;
  background-color: #272822;
  font-family: Roboto;
  min-width: 320px;
  margin: 1vh 3vw;
}

h1 {
  font-family: "Space Mono", monospace;
  font-weight: 700;
  font-size: 2.5rem;
  margin-top: 0;
  margin-bottom: 0.5rem;
  line-height: 1.2;
}

h3 {
  font-size: 24px;
}

p {
  font-family: Roboto;
}
@media screen and (min-width: 1024px) {
  #main-container {
    display: grid;
    grid-template-columns: 2fr 3fr;
    align-items: start;
  }
}
