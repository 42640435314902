.card {
    border: 1px solid rgb(136, 132, 111);
    border-radius: .25rem;
    max-width: 769px;
    margin: 40px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.card h3 {
    color: #a6e22e;
    margin: 0px 0px 12px;
}
.card-body {
    background-color: #3e3d32;
    color: #f8f8f2;
    height: 100%;
    padding: 1.25rem;
}
.card-body p {
    text-align: center;
    margin: 0;
}
.card-footer {
    background-color: #49483e;
}
img {
    width: 100%;
    min-width: 300px;
    border-radius: .25rem .25rem 0 0 ;
}
button {
    font-size: 14px;
    padding: 6px 12px;
    margin: 15px 20px;
    padding: 0.375rem 1rem;
    display: inline-block;
    text-decoration: none;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-radius: .25rem;
    line-height: 1.5;
}
button:focus, button:active:focus {
    outline: thin dotted;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
}
button:hover, button:focus {
    color: #333;
    text-decoration: none;
}
button:active {
    background-image: none;
    outline: 0;
    -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
}
.primary-button {
    background-color: #f92672;
    border-color: #f92672;
    color: #fff 
}
.secondary-button {
    background-color: #66d9ef;
    border-color: #66d9ef;
    color: #272822 
}