#grid {
    display: grid;
    grid-template-columns: 1fr;
}

@media screen and (min-width: 769px){
    #grid {
        grid-template-columns: 1fr 1fr;
   }
}
